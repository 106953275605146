<template>
  <AppRow only-owner>
    <template v-slot>
      <v-col cols="12">
        <v-text-field
          v-model="searchText"
          background-color="#464646"
          class="rounded-lg"
          flat
          hide-details="auto"
          label="Поиск пользователей"
          solo
        >
          <template v-slot:prepend-inner>
            <v-icon class="mr-1">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <v-select
          v-model="role"
          :items="roles"
          background-color="#464646"
          class="rounded-lg"
          clearable
          flat
          hide-details="auto"
          label="Роль"
          solo
        />
      </v-col>

      <v-col
        cols="12"
        sm="6"
      >
        <DatePicker
          label="Дата регистрации (выбрать промежуток)"
          @save="saveDates"
        />
      </v-col>

      <v-col cols="12">
        <v-card
          class="px-4 pb-3"
          flat
        >
          <v-card-title class="py-7">
            Пользователи:
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="[]"
            :items-per-page="5"
          >
            <template v-slot:[`item.createdAt`]="{ item }">
              {{ item.createdAt | moment('DD.MM.YYYY, HH:mm') }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </template>
  </AppRow>
</template>

<script>
import AppRow from '@/components/AppRow.vue';
import DatePicker from '@/components/DatePicker.vue';

export default {
  components: {
    AppRow,
    DatePicker,
  },
  data() {
    return {
      searchText: '',
      searchTimeout: null,
      search: '',
      role: '',
      roles: ['ROLE_UNDEFINED', 'USER', 'ADMINISTRATOR', 'SUPERUSER', 'PARTNER', 'BLOGGER', 'BANNED', 'PUBLIC'],
      registeredFrom: '',
      registeredTo: '',
      headers: [
        { text: 'ID:', value: 'id', class: 'white--text text-subtitle-2' },
        { text: 'Имя:', value: 'username', class: 'white--text text-subtitle-2' },
        { text: 'Роль:', value: 'role', class: 'white--text text-subtitle-2' },
        { text: 'Дата регистрации:', value: 'createdAt', class: 'white--text text-subtitle-2' },
        { text: 'Кол-во кликов:', value: 'clicks', class: 'white--text text-subtitle-2' },
        { text: 'Кол-во регистраций:', value: 'registrations', class: 'white--text text-subtitle-2' },
        { text: 'Кол-во первых депозитов:', value: 'firstDeposits', class: 'white--text text-subtitle-2' },
        { text: 'Сумма первых депозитов:', value: 'amountFirstDeposits', class: 'white--text text-subtitle-2' },
        { text: 'Процент повторного депозита:', value: 'percent', class: 'white--text text-subtitle-2' },
        { text: 'Сумма всех депозитов:', value: 'amoutDeposits', class: 'white--text text-subtitle-2' },
      ],
    };
  },
  watch: {
    searchText(value) {
      clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        // this.firstPage();
        this.search = value;
      }, 500);
    },
  },
  methods: {
    saveDates(dates) {
      [this.registeredFrom, this.registeredTo] = dates;
    },
  },
};
</script>
